import React, { useEffect, useState } from "react";
import { Centering } from "./utils/components";
import { isMobile } from "./utils/utils";
var AGE_SCALE = 80;
var BOLDNESS = 20;
var AGE_HAND_LENGTH_RATIO = 0.5;
var YEAR_HAND_LENGTH_RATIO = 0.9;
var COLOR = "black";
export function Clock(_a) {
    var birthday = _a.birthday;
    var viewHeight = isMobile() ? 600 : 600;
    var viewWidth = isMobile() ? 600 : 800;
    var radius = isMobile()
        ? (viewWidth / 2 - 96) // 96 is a seemingly good padding value
        : viewWidth / 4;
    var _b = useState(), error = _b[0], setError = _b[1];
    var _c = useState(), remainingLife = _c[0], setRemainingLife = _c[1];
    var _d = useState(), remainingDaysOfYear = _d[0], setRemainingDaysOfYear = _d[1];
    var _e = useState(0), ageX = _e[0], setAgeX = _e[1];
    var _f = useState(0), ageY = _f[0], setAgeY = _f[1];
    var _g = useState(0), yearX = _g[0], setYearX = _g[1];
    var _h = useState(0), yearY = _h[0], setYearY = _h[1];
    useEffect(function () {
        // Reset states in case of invalid input
        setError(undefined);
        setRemainingLife(undefined);
        setAgeX(0);
        setAgeY(0);
        setRemainingDaysOfYear(undefined);
        setYearX(0);
        setYearY(0);
        var birthYear = birthday === null || birthday === void 0 ? void 0 : birthday.year;
        if (!birthYear) {
            return;
        }
        if (Number.isNaN(birthYear)) {
            setError("invalid value");
            return;
        }
        // looks like the input is valid birthday...
        var now = new Date();
        var currentYear = now.getFullYear();
        // Age hand
        var age = currentYear - birthYear;
        if (age < 0) {
            setError("you are not born yet.");
            return;
        }
        var ageDiff = AGE_SCALE - age;
        setRemainingLife(ageDiff.toString());
        function calc(current, scale) {
            // FIXME: use radian
            // current : scale = r : 360
            console.log("current=".concat(current));
            console.log("scale=".concat(scale));
            console.log("ratio=".concat((360 * current / scale)));
            // Minus 450 to fix to clock-angle
            var r = 450 - (360 * current / scale);
            console.log("ab_ratio=".concat(r > 360 ? r - 360 : r));
            var x = Math.cos(r * Math.PI / 180) * radius;
            var y = Math.sin(r * Math.PI / 180) * radius;
            console.log("x=".concat(x));
            console.log("y=".concat(y));
            return { x: x, y: y };
        }
        var _a = calc(age, AGE_SCALE), ageX = _a.x, ageY = _a.y;
        setAgeX(ageX);
        setAgeY(ageY);
        // Year hand
        var currentDay = (function () {
            var start = new Date(now.getFullYear(), 0);
            var diff = now.getTime() - start.getTime();
            return Math.floor(diff / (24 * 60 * 60 * 1000));
        })();
        var daysInYear = 
        // is leap?
        ((currentYear % 4 === 0) || (currentYear % 4 === 0) && (currentYear % 100 !== 0)) ? 366 : 365;
        setRemainingDaysOfYear((daysInYear - currentDay).toString());
        var _b = calc(currentDay, daysInYear), yearX = _b.x, yearY = _b.y;
        setYearX(yearX);
        setYearY(yearY);
    }, [birthday]);
    return (<Centering style={isMobile() ? {} : { padding: "1rem" }}>
      <div style={{ display: "flex", justifyContent: "center" }}>
        <svg viewBox={"0 0 ".concat(viewWidth, " ").concat(viewHeight)} style={{ width: viewWidth, maxWidth: "100vmin" }}>
          <circle cx={viewWidth / 2} cy={viewHeight / 2} r={radius} fill="none" stroke={COLOR} strokeWidth={BOLDNESS}/>
          {!error && ageX && ageY && (<>
              <line x1={viewWidth / 2} y1={viewHeight / 2} x2={(ageX * AGE_HAND_LENGTH_RATIO) + (viewWidth / 2)} y2={(viewHeight / 2) - (ageY * AGE_HAND_LENGTH_RATIO)} stroke={COLOR} strokeWidth={BOLDNESS} stroke-linecap="round"/>
              <line x1={viewWidth / 2} y1={viewHeight / 2} x2={(yearX * YEAR_HAND_LENGTH_RATIO) + (viewWidth / 2)} y2={(viewHeight / 2) - (yearY * YEAR_HAND_LENGTH_RATIO)} stroke={COLOR} strokeWidth={BOLDNESS} stroke-linecap="round"/>
            </>)}
        </svg>
      </div>

      <Centering style={{ padding: "1rem" }}>
        <p style={{ margin: 0 }}>Short hand: days left in your life</p>
        <p style={{ margin: 0 }}>Long hand: days left in this year</p>
      </Centering>

      <Centering>
        {remainingDaysOfYear && <p style={{ margin: 0 }}>You have {remainingDaysOfYear} days left this year.</p>}
      </Centering>
      <Centering>
        {remainingLife && <p style={{ margin: 0 }}>You have {remainingLife} years left, if you die at 80.</p>}
      </Centering>

      <Centering style={{ padding: "1rem" }}>
        {error && <p style={{ margin: 0 }}>{error}</p>}
      </Centering>
    </Centering>);
}
