import React, { useCallback, useEffect, useState } from "react";
import { Centering } from "./utils/components";
import { DateTime } from "luxon";
var LOCAL_STORAGE_KEY = "birthYear";
export function BirthDayInput(_a) {
    var _b;
    var onChange = _a.onChange;
    var _c = useState(), birthday = _c[0], setBirthday = _c[1];
    var handleChange = useCallback(function (inputBirthday) {
        if (!inputBirthday) {
            onChange(undefined);
            return;
        }
        var birthday = DateTime.fromJSDate(inputBirthday);
        if (!birthday.isValid) {
            return;
        }
        setBirthday(birthday);
        // It's very unlikely to be born before 1900 or after 2100.
        if (birthday.year > 2100 || birthday.year < 1900) {
            onChange(undefined);
            return;
        }
        // save for later accesses
        localStorage.setItem(LOCAL_STORAGE_KEY, birthday.toMillis().toString());
        onChange(birthday);
    }, [onChange]);
    var handleChangeCb = useCallback(function (e) {
        handleChange(e.target.valueAsDate);
    }, [handleChange]);
    useEffect(function () {
        var saved = localStorage.getItem(LOCAL_STORAGE_KEY);
        if (saved) {
            console.log("read from localstorage");
            var parsed = DateTime.fromMillis(Number.parseInt(saved));
            if (parsed.isValid) {
                handleChange(parsed.toJSDate());
            }
        }
    }, [handleChange]);
    return (<Centering>
      <div>
        <label htmlFor="birth-year">Your birth year: </label>
        <input id="birth-year" type="date" value={(_b = birthday === null || birthday === void 0 ? void 0 : birthday.toISODate()) !== null && _b !== void 0 ? _b : ""} onChange={handleChangeCb} style={{ width: "10em" }}/>
      </div>
    </Centering>);
}
