var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useCallback, useEffect, useState } from "react";
export function Tab(props) {
    var _a = useState(props.children.length > 0 ? props.children[0].name : undefined), currentTabName = _a[0], setCurrentTabName = _a[1];
    useEffect(function () {
        var url = new URL(location.href);
        var requestedTab = url.searchParams.get("tab");
        if (requestedTab && currentTabName !== requestedTab && props.children.find(function (t) { return t.name === requestedTab; })) {
            setCurrentTabName(requestedTab);
        }
    }, [currentTabName, props.children]);
    var handleChange = useCallback(function (tabName) {
        // Save current tab name as a query parameter
        var newUrl = new URL(location.href);
        newUrl.searchParams.set("tab", tabName);
        history.pushState({}, "", newUrl);
        setCurrentTabName(tabName);
    }, []);
    return (<div style={{ paddingTop: "1rem" }}>
      <div style={{ display: "flex" }}>
        <div style={{ width: "1em", borderBottom: "1px solid black" }}/>
        {props.children.map(function (tab) {
            return <p style={__assign(__assign(__assign({ padding: "1em", borderStyle: "solid", borderWidth: "1px" }, (tab.name === currentTabName && { borderColor: "black black transparent black" })), (tab.name !== currentTabName && { borderColor: "transparent transparent black transparent" })), { cursor: "pointer" })} onClick={function (_) { return handleChange(tab.name); }}>
            {tab.name}
          </p>;
        })}
        <div style={{ borderBottom: "1px solid black", flexGrow: 1 }}/>
      </div>
      {props.children.map(function (_a) {
            var name = _a.name, component = _a.component;
            return (<div style={{
                    display: name === currentTabName ? "block" : "none",
                    width: "100vw"
                }}>
          {component}
        </div>);
        })}
    </div>);
}
