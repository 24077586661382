var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from "react";
export function Centering(_a) {
    var children = _a.children, style = _a.style;
    return (<div style={__assign({ display: "flex", justifyContent: "center" }, style)}>
      <div>
        {children}
      </div>
    </div>);
}
